import * as React from 'react';
import { graphql, Link } from 'gatsby';
import styles from './flitsen.module.scss';
import Header from '../components/header';
import SEO, { SeoModel } from '../components/seo';
import Footer from '../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookOpen, faDownload, faTh } from '@fortawesome/free-solid-svg-icons'

interface FlitsenTemplateProps {
  location: Location,
  data: {flitsendataJson: FlitsenData}
}

export interface FlitsenData {
  fields: {
    slug: string
  }
  volume: number
  volumeYear: number
  volumeNumber: number
  publicationDate: Date
  publicationDateText: string
  bannerImage: any
  coverImage: any
  file: string
  fileLoRes: string
}

export const flitsenTemplateQuery = graphql`
  query FlitsenTemplateQuery($slug: String!) {
    flitsendataJson(fields: { slug: {eq: $slug} }) {
      volume
      volumeYear
      volumeNumber
      publicationDate
      publicationDateText
      bannerImage
      coverImage
      file
      fileLoRes
    }
  }
`;

const FlitsenTemplate: React.FC<FlitsenTemplateProps> = ({
  location,
  data
}: FlitsenTemplateProps) => {
  const { flitsendataJson: node } = data;
  const seo: SeoModel = {
    title: `Jubal Flitsen ${node.volumeYear} No. ${node.volumeNumber} - ${node.publicationDateText}`,
    description: 'Lees hier het digitale roemruchte clubblad van Jubal Dordrecht!',
    imageUrl: node.bannerImage,
    isArticle: true,
  };

  const flitsenPageNumber = 1;
  const flitsenPdfOptions = `#page=${flitsenPageNumber}&toolbar=0`;

  return (
    <div id="top" className="d-flex flex-column w-100 h-100 mainBackground overflow-hidden">
      <Header />
      <SEO model={seo} />
      <div className="mt-5 overflow-hidden" />
      <section id="flitsen" className={`bg-light pt-4 pb-0 w-100 overflow-hidden ${styles.pdfObjectContainerCorrection}`}>
        <p className="p-3 mb-0 text-center">
          <Link className="float-left" to={`/flitsen`} title="Uitgavenoverzicht">
            <FontAwesomeIcon icon={faTh} />
          </Link>
          <a href={`${node.file}${flitsenPdfOptions}`} target="_blank">
            Klik hier om de Jubal Flitsen te openen in een nieuw tabblad
          </a>
          {node.fileLoRes ? (
            <>
              <span> / </span>
              <a className="text-secondary" href={`${node.fileLoRes}${flitsenPdfOptions}`} target="_blank">
                of open de Lite versie (lage resolutie).
              </a>
            </>
          ) : (<div></div>)}
        </p>
        <object
          data={`${node.file}${flitsenPdfOptions}`}
          type="application/pdf"
          width="100%"
          height="100%"
          className={`vh-100 ${styles.pdfObjectCorrection}`}
        >
          <div className="m-3 mb-5 px-3 text-center">
            <a href={`${node.file}${flitsenPdfOptions}`} target="_blank" className="deco-none">
              <h3 className="mt-5">Jaargang {node.volume}</h3>
              <h5>{node.volumeYear} No. {node.volumeNumber} - {node.publicationDateText}</h5>
              <p className="p-3 mb-0 text-center">
                <a href={`${node.file}${flitsenPdfOptions}`} target="_blank">
                  <FontAwesomeIcon icon={faBookOpen} size="3x" />
                </a>
              </p>
              <p className="p-3 mb-0 text-center">
                <Link to={`/flitsen`}>
                  Terug naar uitgavenoverzicht.
                </Link>
              </p>
            </a>
            <div className={styles.spacer} />
          </div>
        </object>
      </section>
      <Footer isFixedBottom={false} isSitemapHidden={false} location={location} />
    </div>
  );
}

export default FlitsenTemplate;